import React from "react";
import Image from "../Images/Image/Img-2.jpg";
import ImageMind from "../Components/ImageMind";

function BusinessStart() {
  return (
    <>
      <ImageMind
        heading="Registering an LLC Can Be More Complex and Costly"
        text="There's a part to do to register an LLC. Changing state prerequisites, various steps and parcels of critical documentation take off bounty of room for mistake. Errors can be expensive — that's where we come in."
        Image={Image}
      />
    </>
  );
}

export default BusinessStart;
