import React from 'react'
import ImageMind from '../Components/ImageMind'
import  Image from "../Images/Image/Img-3.jpg";

function Tools() {
  return (
    <div>
      <ImageMind heading="Past LLC Formation: All the Strategies to Develop and Handle Your Business"
    text="GYB Firm's complete set of services has you secured — from getting your EIN and business licenses to submitting your yearly report. Only select the administrations to suit your business as and after you require them." Image={Image} />
    </div>
  )
}

export default Tools