import React, { useState } from 'react'
import Card3 from '../../../Components/Card3';
import LargeText from '../../../Components/LargeText';
import Image8 from "../../../Images/Icons/PNG/48.png";
import Image9 from "../../../Images/Icons/PNG/15.png";
import Image12 from "../../../Images/Icons/PNG/65.png";
import Image13 from "../../../Images/Icons/PNG/66.png";
import Image14 from "../../../Images/Icons/PNG/67.png";
import Image15 from "../../../Images/Icons/PNG/21.png";
import Image16 from "../../../Images/Icons/PNG/25.png";
import Image17 from "../../../Images/Icons/PNG/19.png";
import Image18 from "../../../Images/Icons/PNG/6.png";
import Image19 from "../../../Images/Icons/PNG/71.png";
function AboutCompany(props) {


    const [activeButton, setActiveButton] = useState(1);

    const handleButtonClick1 = () => {
        setActiveButton(1);
    }
    const handleButtonClick2 = () => {
        setActiveButton(2);
    }
    const handleButtonClick3 = () => {
        setActiveButton(3);
    }
    const handleButtonClick4 = () => {
        setActiveButton(4);
    }

    return (
        <>
            <div className='ein-row'>
                <center>
                    <LargeText heading="We Give You Everything You Need, Whenever You Need It" text="As your business gets bigger, we will always be there to help you have the things you need to keep serving your company's ongoing needs." />
                </center>
                <div className='ein-row-btn-div'>
                    <button className={activeButton === 1 ? 'ein-row-btn-active' : 'ein-row-btn '} onClick={handleButtonClick1}>Change Company</button>
                    <button className={activeButton === 2 ? 'ein-row-btn-active' : 'ein-row-btn '} onClick={handleButtonClick2}>Complains</button>
                    <button className={activeButton === 3 ? 'ein-row-btn-active' : 'ein-row-btn '} onClick={handleButtonClick3}>Register Agent</button>
                    <button className={activeButton === 4 ? 'ein-row-btn-active' : 'ein-row-btn '} onClick={handleButtonClick4}>IRS Filling</button>
                </div>
                <div className='ein-row-data'>
                    {activeButton === 1 && <>
                        <div className='ein-row-data-component'>
                            {props.changeimage1 &&
                                <Card3 path="/amendement" Image={Image8}
                                    heading="Amendment"
                                    anotherclass="ein-row-data-component-card"
                                    text="Filed if a company requires changes to membership, addresses or company name."/>}
                            {props.changeimage2 &&
                                <Card3 path="/dissolution" Image={Image9}
                                    heading="Dissolution"
                                    anotherclass="ein-row-data-component-card"
                                    text="Used to formally terminate the existence of a entity." />}
                            {props.changeimage3 &&
                                <Card3 Image={props.changeimage3}
                                    heading={props.changeheading3} anotherclass="ein-row-data-component-card"
                                    text={props.changetext3} />}
                            {props.changeimage4 &&
                                <Card3 Image={props.changeimage4}
                                    heading={props.changeheading4} anotherclass="ein-row-data-component-card"
                                    text={props.changetext4} />}
                            {props.changeimage5 &&
                                <Card3 Image={props.changeimage5}
                                    heading={props.changeheading5} anotherclass="ein-row-data-component-card"
                                    text={props.changetext5} />}
                        </div>
                    </>}
                    {activeButton === 2 && <>
                        <div className='ein-row-data-component'>
                            {props.complianceimage1 &&
                                <Card3 Image={Image12} path="/annual-report"
                                    heading="File Annual Report" anotherclass="ein-row-data-component-card"
                                    text="The majority of states require that companies file periodic reports that affirm the current information of the companies members and business address." />}
                            {props.complianceimage2 &&
                                <Card3 Image={Image13} path="/certificate-of-good-standing"
                                    heading="Certificate of Good Standing" anotherclass="ein-row-data-component-card"
                                    text="Required by governmental and private agencies to validate a companies status in order to facilitate specified transactions. " />}
                            {props.complianceimage3 &&
                                <Card3 Image={Image14} path="/research-package"
                                    heading="Business License Search" anotherclass="ein-row-data-component-card"
                                    text="Determine all business licenses and permits required at the federal, state, county, and municipal level." />}
                            {props.complianceimage4 &&
                                <Card3 Image={props.complianceimage4}
                                    heading={props.complianceheading4} anotherclass="ein-row-data-component-card"
                                    text={props.compliancetext4} />}
                            {props.complianceimage5 &&
                                <Card3 Image={props.complianceimage5}
                                    heading={props.complianceheading5} anotherclass="ein-row-data-component-card"
                                    text={props.compliancetext5} />}
                        </div>
                    </>}
                    {activeButton === 3 && <>
                        <div className='ein-row-data-component'>
                            {props.agentimage1 &&
                                <Card3 Image={Image15} path="/registered-agent"
                                    heading="Order Registered Agent" 
                                    anotherclass="ein-row-data-component-card"
                                    text="Order registered agent service and receive your new registered agent information immediately." />}
                            {props.agentimage2 &&
                                <Card3 Image={Image16} path="/registered-agent"
                                    heading="Renew Registered Agent" anotherclass="ein-row-data-component-card"
                                    text="Click here if you have existing registered agent service and would like to renew it. " />}
                            {props.agentimage3 &&
                                <Card3 Image={Image17} path="/research-package"
                                    heading="Business License Search" anotherclass="ein-row-data-component-card"
                                    text="Use to update the registered agent on file with the state of formation." />}
                            {props.cagentmage4 &&
                                <Card3 Image={props.agentimage4}
                                    heading={props.agentheading4} anotherclass="ein-row-data-component-card"
                                    text={props.agenttext4} />}
                            {props.agentimage5 &&
                                <Card3 Image={props.agentimage5}
                                    heading={props.agentheading5} anotherclass="ein-row-data-component-card"
                                    text={props.agenttext5} />}

                        </div>
                    </>} {activeButton === 4 && <>
                        <div className='ein-row-data-component'>
                            {props.fillingimage1 &&
                                <Card3 Image={Image18}path="/file-dba"
                                    heading="File Business Taxes" anotherclass="ein-row-data-component-card"
                                    text="Order registered agent service and receive your new registered agent information immediately." />}
                            {props.fillingimage2 &&
                                <Card3 Image={Image19}path="/registered-agent"
                                    heading="Renew Registered Agent" anotherclass="ein-row-data-component-card"
                                    text="Click here if you have existing registered agent service and would like to renew it. " />}
                            {props.fillingimage3 &&
                                <Card3 Image={props.fillingimage3}
                                    heading={props.fillingheading3} anotherclass="ein-row-data-component-card"
                                    text={props.fillingtext3} />}
                            {props.fillingimage4 &&
                                <Card3 Image={props.fillingimage4}
                                    heading={props.fillingheading4} anotherclass="ein-row-data-component-card"
                                    text={props.fillingtext4} />}
                            {props.fillingimage5 &&
                                <Card3 Image={props.fillingimage5}
                                    heading={props.fillingheading5} anotherclass="ein-row-data-component-card"
                                    text={props.fillingtext5} />}

                        </div>
                    </>}

                </div>
            </div>
        </>
    )
}

export default AboutCompany