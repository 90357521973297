import React from 'react'
import ClientsData from '../Components/ClientsData'
import Image from "../Images/Image/Client-Image.webp"

function Clients() {
  return (
    <div className='clients'>
      <ClientsData heading="nabeelzubair427" time="4 months ago" state="Saudi Arabia" 
      about="Thanks for quick service, got my IL reseller certificate within 2 days." image="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/28293fc4644de7141d7bc14d90115ba3-921758121693603165.36742/C6351E9E-3F3A-4896-86A1-11D2595F0DFA" />
      <ClientsData heading="cynthia4488" time="8 months ago" state="United States" 
      about="Great job very fast and easy to work with, definitely recommend." image="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/057dc72b87ce58778f1256c2207aa970-1612106619213/3a92c372-a71f-4443-8090-d374d3093b89.jpg" />
      <ClientsData heading="cosmetology2323" time="11 months ago" state="Spain" 
      about="hello, they just handed me the job and I'm already in charge of two more, the treatment is excellent, they are professionals in everything." image={Image} />
      <ClientsData heading="joscaden2010" time="11 months ago" state="Ukraine" 
      about="If there are 3 words i have to define with this experience of working with Ibrahim, it would be lightning fast, very dependable and trustworthy." image="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/e6eb4ec05db506a876e626b339a44ac1-1690991023033/d521bd99-764f-420c-9b97-45f8116dc61a.jpeg" />
      <ClientsData heading="lil_50" time="10 months ago" state="Ukraine" 
      about="He was so amazing, kind, and efficient. He was on top of everything and made the process really smooth for me! " image="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/be9c3dac9be1cd161edef83601a3b611-485132591691435713.891962/BE6160F4-65C1-4F69-B363-3FDFF75E820C"/>
      <ClientsData heading="rmason33" time="4 months ago" state="United States" 
      about="I am a frequent buyer on fiverr and so happy I came across Ibrahim, he is amazing at what he does." image="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/5f42910c6f360a9432f4132ff1704fb5-1639502614205/10a68fb6-0f7b-4fe3-85e2-5444e49916a2.png" />

    </div>
  )
}

export default Clients