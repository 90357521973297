import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { Container } from 'react-bootstrap';
import Blogcard from '../Components/Blogcard';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Blogs() {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { slug } = useParams();

    useEffect(() => {
        fetch(`https://order.gybfirm.com/api/post/${slug}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then(data => {
                console.log('Fetched data:', data); // Log fetched data to console
                setData(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }, [slug]);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Container>
                <div className='Blog-page'>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : data === null ? (
                        <p>No data available</p>
                    ) : (
                        <div>
                            <Helmet>
                                <title>{data.title}</title>
                                <meta name="description" content={data.meta} />
                            </Helmet>
                            <div>   
                                <img src={data.image} alt="" className="blog-bg-image" />
                            </div>
                            <div className='px-4'>
                                <small style={{ float: "right", marginTop: "1rem" }}>
                                    <i className="fa fa-calendar me-2" style={{ color: "#FAAF40" }} aria-hidden="true"></i>
                                    {data.updated_at}
                                </small>
                                <br />
                                <h3 className='blog-heading'>{data.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: data.meta_description }} />
                                {/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}
                            </div>
                        </div>
                    )}
                    <Blogcard />
                </div>
            </Container>
        </motion.div>
    );
}

export default Blogs;
