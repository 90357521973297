import React from 'react'                           

function TrustPilotExcellenceB() {
  return (
    <div>
        <div className='trust-pilot-excellence-a'>
      <div className='trust-pilot-excellence-a-row'>
      <h1 className='trust-pilot-excellence-a-heading'>
      Bring Your Business to Life It’s Free, It’s Easy and It’s Stress-Free
      </h1>
       </div>
    </div>
    </div>
  )
}

export default TrustPilotExcellenceB