import React from 'react'
import Image from "../Images/Image/HomePage-D.png"

function Asthetics() {
  return (
    <div className='asthetics'>
      <img src={Image} alt=""  className='asthetics-image'/>
    </div>
  )
}

export default Asthetics