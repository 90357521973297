import React, { useEffect, useState } from 'react';
import ServicesDropDown from "./ServicesDropDown"
import { motion } from "framer-motion"
import ResourcesCenterDropDown from "./ResourcesCenterDropDown"
import AboutDropDown from "./AboutDropDown"
import MainButton from '../Components/MainButton';
import { Link } from 'react-router-dom'
// import MainButton from '../Components/MainButton'
import Logo from "../Images/Image/GYB.png"
function Header() {
  const phoneNumber = '+15752476989'; 
  const [show, setShow] = useState(false);
  const [Services, setServices] = useState(false);
  const [Resources, setResources] = useState(false);
  const [About, setAbout] = useState(false);
  // medis dropdown query
  const [showNamesa, setShowNamesa] = useState(true);
  const [showNamesb, setShowNamesb] = useState(true);
  const [showNamesc, setShowNamesc] = useState(true);




  // media query logic
  const ServicesDrop = () => {
    setResources(false)
    setAbout(false)
    setServices(true);
  }
  const ResourcesDrop = () => {

    setAbout(false)
    setServices(false);
    setResources(true)
  }
  const AboutDrop = () => {
    setResources(false)
    setServices(false);
    setAbout(true)
  }
  const ResponsiveBtn = () => {
    if (!show) {
      document.getElementById('navbar').style.height = "auto";
      setShow(true);
    }
    else {
      setShow(false);
      document.getElementById('navbar').style.height = "80px";

    }
  }



  const handleTogglea = () => { setShowNamesa(!showNamesa); };
  const handleToggleb = () => { setShowNamesb(!showNamesb); };
  const handleTogglec = () => { setShowNamesc(!showNamesc); };
  useEffect(() => {
    // set initial value
    const mediaWatcher = window.matchMedia("(min-width: 991px)")
    setShowNamesa(mediaWatcher.matches);
    setShowNamesb(mediaWatcher.matches);
    setShowNamesc(mediaWatcher.matches);
    //watch for updates
    function updateIsNarrowScreen(e) {
      setShowNamesa(e.matches);
      setShowNamesb(e.matches);
      setShowNamesc(e.matches);
    }
    mediaWatcher.addEventListener('change', updateIsNarrowScreen)
    // clean up after ourselves
    return function cleanup() {
      mediaWatcher.removeEventListener('change', updateIsNarrowScreen)
    }

  }, [])
  return (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} >
        <nav className="navbar navbar-expand-lg bg-white " id="navbar" >
          <div className="container-fluid">
            <Link className="navbar-brand m-0 p-0 pb-2 me-lg-4" to="/">
              <img src={Logo} alt="" className='header-logo' />
            </Link>
            <Link to={`whatsapp://send?phone=${phoneNumber}`} 
             className='header-tel-link d-flex align-items-center justify-content-center header-links3 '>
              <i class="fa-solid fa-phone header-phone-icon"></i>
            </Link>
            <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              onClick={ResponsiveBtn} >

              {show ? <i class="fa-solid fa-times mobile-icon"></i> :
                <i class="fa-solid fa-bars mobile-icon"></i>}
            </button>
            <div className={` nav-link-container ${show ? 'collapse navbar-collapse ' : 'collapse navbar-collapse  active'}`} id="navbarSupportedContent">
              {show &&
                <>
                  <div className="footer-b-column">
                    <Link to="/how-it-start" className='d-flex justify-content-between'>
                      <h4 className=' footer-b-column-heading mt-3 mb-1'> <i className="fa-solid fa-arrow-right header-link-arrow"></i> How it starts </h4>
                    </Link>

                  </div>
                  <div className="footer-b-column">
                    <Link to="/blog" className='d-flex justify-content-between'>
                      <h4 className=' footer-b-column-heading  mb-1'> <i className="fa-solid fa-arrow-right header-link-arrow"></i> Blog </h4>
                    </Link>

                  </div>
                  <div className="footer-b-column">
                    <div className='d-flex justify-content-between'>
                      <h4 className=' footer-b-column-heading'> <i class="fa-solid fa-arrow-right header-link-arrow"></i> Services </h4>
                      <button onClick={handleTogglea} className="down-icon">
                        {showNamesa ? <><i class="fas fa-chevron-up"></i></> : <><i class="fas fa-chevron-down"></i></>}
                      </button>
                    </div>
                    <div className={`footer-b-names ${showNamesa ? 'show' : 'hide'}`}>
                      {
                        showNamesa &&
                        <div className='d-flex flex-column gap-1'>
                          {/* <Link to="/llc" className='footer-b-text'>LLC</Link>
                          <Link to="/form-s-corporation" className='footer-b-text'>S Corporation</Link>
                          <Link to="/form-c-corporation" className='footer-b-text'>C Corporation</Link>
                          <Link to="/start-a-nonprofit" className='footer-b-text'>Nonprofit</Link>
                          <Link to="/amendement" className='footer-b-text'>Amendement</Link> */}
                          <Link to="/amendement" className='footer-b-text'>Amendement</Link>
                          <Link to='/company-name-change' className='footer-b-text'>Company Name Change</Link>
                          <Link to='/registered-agent' className='footer-b-text'>Registered Agent</Link>
                          <Link to='/virtual-address' className='footer-b-text'>Virtual Address</Link>
                          <Link to='/tax-id-ein' className='footer-b-text'>EIN (Employer Identification No.)</Link>
                          <Link to="/certificate-of-good-standing" className='footer-b-text'>Cerficate of Good Standing</Link>
                          <Link to="/file-dba" className='footer-b-text'>Doing Buisness As` Name (DBA)</Link>
                          <Link to="/tax-exemption" className='footer-b-text'>Tax Exemption of US Marketplaces</Link>
                          <Link to="/change-of-registered-agent" className='footer-b-text'>Change of Registered Agent</Link>
                          <Link to="/research-package" className='footer-b-text'>Buisness License or Premit</Link>
                          <Link to="/annual-report" className='footer-b-text'>Submit  an Annual Report</Link>
                          <Link to="/business-accounting" className='footer-b-text'>Get a Free Text Consultation</Link>
                          <Link to="llc-s-corp-election" className='footer-b-text' >File an S  Crop Election</Link>
                          <Link to="/trademark-name-search" className='footer-b-text'>Get a Trademark</Link>
                          <Link to="/corporate-llc-kit" className='footer-b-text' >Order a Corporative / LLC kit</Link>
                          <Link to="/business-finance-accounting" className='footer-b-text'>Finance and Accounting</Link>
                          <Link to="/dissolution" className='footer-b-text' >Disslove your Comapny</Link>
                          <Link to="/reinstatement-order" className='footer-b-text'>Get Reinstated</Link>
                        </div>
                      }

                    </div>

                  </div>
                  {/*  INFORMATION LAB*/}
                  <div className="footer-b-column">
                    <div className='d-flex justify-content-between'>
                      <h4 className=' footer-b-column-heading'> <i class="fa-solid fa-arrow-right header-link-arrow"></i> Information Lab </h4>
                      <button onClick={handleToggleb} className="down-icon">
                        {showNamesb ? <><i class="fas fa-chevron-up"></i></> : <><i class="fas fa-chevron-down"></i></>}
                      </button>
                    </div>
                    <div className={`footer-b-names ${showNamesb ? 'show' : 'hide'}`}>
                      {
                        showNamesb &&
                        <div className='d-flex flex-column gap-1'>
                          <Link to="/business-entity-comparison" className='footer-b-text'>Compare Business Entities</Link>
                          <Link to="/business-name-generator" className='footer-b-text'>Business Name Generator</Link>
                          <Link to="/start-a-business" className='footer-b-text'>Business Industry Guides</Link>
                          <Link to="/business-name-search" className='footer-b-text'>Business Name Search Tool</Link>
                          <Link to="/guides-start-business" className='footer-b-text'>Business Startup Guide</Link>
                          <Link to="/limited-liability-company" className='footer-b-text'>What is an LLC?</Link>
                          <Link to="/state-filing-fees" className='footer-b-text'>State Filling Fees</Link>
                        </div>
                      }

                    </div>

                  </div>
                  {/*  ABOUT US*/}
                  <div className="footer-b-column">
                    <div className='d-flex justify-content-between'>
                      <h4 className=' footer-b-column-heading'> <i class="fa-solid fa-arrow-right header-link-arrow"></i> About Us </h4>
                      <button onClick={handleTogglec} className="down-icon">
                        {showNamesc ? <><i class="fas fa-chevron-up"></i></> : <><i class="fas fa-chevron-down"></i></>}
                      </button>
                    </div>
                    <div className={`footer-b-names ${showNamesc ? 'show' : 'hide'}`}>
                      {
                        showNamesc &&
                        <div className='d-flex flex-column gap-1'>
                          <Link to="/testimonials" className='footer-b-text'>Testimonials</Link>
                          <Link to="/choose-gybfirm" className='footer-b-text'>Why Choose Us</Link>
                          <Link to="/contact-us" className='footer-b-text'>Contact Us</Link>
                          <Link to='/new-sales' className='footer-b-text'>New Clients</Link>
                        </div>
                      }

                    </div>

                  </div>
                  <div>
                    {/* <Link to="tel:+92-326-764-7709" className=' header-tel-link2 d-flex align-items-center justify-content-start header-links3 ' onClick={() => setShow(false)}>
                      <i class="fa-solid fa-phone"></i>
                      <p>	(575) 247-6989</p>
                    </Link>
                    <MainButton anotherclass="navbar-btn" text="Register Now" path="/register" onClick={() => setShow(false)} />
                    <MainButton anotherclass="navbar-btn2" text="Login" path="/login" onClick={() => setShow(false)} /> */}

                    <MainButton anotherclass="navbar-btn" text="Order Now" path="https://www.order.gybfirm.com/order" />


                  </div>

                </>}

              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/* How It Starts */}
                <li className="nav-item header-link4">
                  <Link className="nav-link " aria-current="page" to="/how-it-start"  >How It Starts</Link>
                </li>
                {/* servives */}
                <li className="nav-item header-link4">
                  <Link className="nav-link " aria-current="page" to="/blog"  >Blog</Link>
                </li>
                {/* servives */}
                <li className="nav-item dropdown header-link4">
                  <Link className="nav-link " to="/"
                    onMouseEnter={ServicesDrop}
                    onMouseLeave={() => setServices(false)}
                  >
                    Services
                    {Services ? <><i class="fas fa-arrow-up"></i></> : <><i class="fas fa-arrow-down"></i></>}
                  </Link>
                  {
                    Services && <>
                      <div
                        onMouseEnter={ServicesDrop}
                        onMouseLeave={() => setServices(false)} >
                        <ServicesDropDown onClick={() => setServices(false)} />
                      </div>
                    </>}

                </li>
                {/* resources */}
                <li className="nav-item dropdown header-link4">
                  <Link className="nav-link " to="/"
                    onMouseEnter={ResourcesDrop}
                    onMouseLeave={() => setResources(false)}   >
                    Information Lab
                    {Resources ? <><i class="fas fa-arrow-up"></i></> : <><i class="fas fa-arrow-down"></i></>}

                  </Link>
                  {
                    Resources && <>
                      <div onMouseEnter={ResourcesDrop} onMouseLeave={() => setResources(false)} >
                        <ResourcesCenterDropDown onClick={() => setResources(false)} />
                      </div>
                    </>}
                </li>
                {/* about */}
                <li className="nav-item dropdown header-link4">
                  <Link className="nav-link " to="/about"
                    onMouseEnter={AboutDrop}
                    onMouseLeave={() => setAbout(false)}  >
                    About Us
                    {About ? <><i class="fas fa-arrow-up"></i></> : <><i class="fas fa-arrow-down"></i></>}
                  </Link>
                  {
                    About && <>
                      <div onMouseEnter={AboutDrop}
                        onMouseLeave={() => setAbout(false)} >
                        <AboutDropDown onClick={() => setAbout(false)} />
                      </div>  </>}
                </li>
              </ul>
            </div>
            <div className="header-links2 d-flex align-items-center justify-content-center ">

            <Link to={`whatsapp://send?phone=${phoneNumber}`}
              className='header-tel-link d-flex align-items-center justify-content-center'>
                <i class="fa-solid fa-phone pe-2 header-phone-icon"></i>
              </Link>
              {/* <p className="nav-item">
                <Link className="nav-link nav-link2 " aria-current="page" to="/login">Login</Link>
              </p> */}
              {/* <MainButton anotherclass="navbar-btn" text="Register Now" path="/register" /> */}
              <MainButton anotherclass="navbar-btn" text="Order Now" path="https://www.order.gybfirm.com/order" />
            </div>
          </div>
        </nav>
      </motion.div>
    </>
  )
}

export default Header