import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion"
import { Container } from 'react-bootstrap'
import axios from 'axios';
import { Link } from 'react-router-dom'
function BlogCard() {
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('https://order.gybfirm.com/api/blog-posts');
                setPosts(response.data.data);
                console.log(response.data)
            } catch (error) {
                setError('Error fetching blog posts. Please try again later.');
                console.error('Error fetching blog posts:', error);
            } finally {
            }
        };

        fetchPosts();
    }, []);


    if (error) {
        return <div>Error: {error}</div>;
    }
    // const parseKeywords = (keywordsString) => {
    //     try {
    //         return JSON.parse(keywordsString);
    //     } catch (error) {
    //         console.error('Error parsing keywords:', error);
    //         return [];
    //     }
    // };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} >
            <Container >

                <div className='blog-main'>
                    {posts.length === 0 ? (
                        <div>No More Posts.</div>
                    ) : (
                        <div className='blog-card-grid'>
                            {posts.map((data) => (
                                <>
                                    <Link to={`/blog/${data.slug}`}>
                                        <div key={data.id} className='blog-card'>
                                            <div>
                                                <img src={data.image} alt="" className='blog-image' />
                                            </div>
                                            <div className='blog-inner-text'>   
                                            <p className='blog-category'> {data.category}</p>
                                            <h6 className='fw-bold mt-2'>{data.title}</h6>
                                            <div>
                                            {/* <dt className='mt-3'>Keywords: {Array.isArray(data.keywords) ? 
                                            data.keywords.map(keyword => keyword.value).join(', ') : parseKeywords(data.keywords).map(keyword => keyword.value).join(', ')}
                                            
                                            </dt> */}
                                            </div>
                                        </div>
                                            </div>
                                    </Link>

                                </>
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </motion.div>
    );
}

export default BlogCard;
