import React from 'react'
import HeroSection from '../../../Home/HeroSection'
import ImageMind from '../../../Components/ImageMind'
import { Container } from 'react-bootstrap'
import ClientSay from '../../../Home/ClientSay'
import ClientsData from "../../../Components/ClientsData"
import video from "../../../Images/video/Corporation.mp4"
import ThumbNail from "../../../Images/Image/HomePage-Video-ThumbNail-B.jpg"
import Image1 from "../../../Images/Image/Img-66.png"
import Image2 from "../../../Images/Icons/PNG/43.png";
import Image3 from "../../../Images/logo/Logo-3.png";
import Image5 from "../../../Images/Image/Img-70.png";
import Image6 from "../../../Images/Image/Img-19.png";
import Image7 from "../../../Images/Image/Img-68.png";
import Image8 from "../../../Images/Image/Img-64.png"
import Image10 from "../../../Images/Image/Img-67.png"
import Image9 from "../../../Images/Image/Img-63.png"
import LLCCheckSection from '../StartBusiness/LLCCheckSection'
import ThreeStepsSection from '../StartBusiness/ThreeStepsSection'
import FAQSection from '../../../Components/FAQSection'

function FinanceAccountion() {
  return (
    <div>
      <Container>
        <HeroSection heading="Stress-Free Accounting and Bookkeeping"
          heroImage={Image1} anotherimgclass="hero-section-image-llc"
          text={< >Business accounting and bookkeeping services at a low monthly cost so you can focus on what matters.</>}
          Gybfirmtext={<p className='w-100' >Join the <span className='fw-bolder'>1,000,000+ </span> businesses that have chosen <span className='fw-bolder'>GYB Firm </span></p>}
          GybfirmImage={Image2}
          trustedimage={Image3}
          btntext="Order Now" btnpath=" https://www.order.gybfirm.com/order"
          trustedheading="More trusted, Verified Reviews"
          trustedrating="51319 rating"
        />
        <ImageMind heading="Keeping on Top of Your Finances Takes a Lot of Effort"
          text="Understanding the tax requirements of your business entity is complex and requires a lot of dedicated time. Trying to do everything yourself can be mentally draining — and financially draining, too when it comes to taxes and bookkeeping!" Image={Image8} />
        <ImageMind anotherClass="flex-md-row-reverse" heading="Take the Hassle Out of Bookkeeping and Accounting"
          text="Get accountant services and solutions tailored to suit your business's needs. With GYB Firm, there are no high fees, mountains of paperwork, or administrative headaches. Get expert knowledge, hands-on support, and peace of mind to make your life easier." Image={Image9} />
        <ImageMind heading="The Benefits of GYB Firm's Accounting & Bookkeeping Services"
          text={<>Our business bookkeeping and accounting service take the worry out of managing your tax and finances while saving you up to  50 hours and $2,500+ per year!" Check out the host of other great benefits below."</>}
          Image={Image10} anotherImgClass="llc-mind-image" />
        <LLCCheckSection
          headone="Stress-Free Tax Filing — Yes, It Is Possible!"
          paraone="Get your business and personal taxes filed for you and get advice on enhancing your potential yearly savings." headtwo="Financial Reporting, Online Tools, & Resources"
          paratwo="Access your mobile-friendly financial reports and tools or easily contact your accountant or bookkeeper anytime, anywhere."
          headthree="Link Up to Four Bank Accounts"
          parathree="Upload receipts to your dashboard to unlock essential business insights and visual profit and loss statements."
          headseven="Compliance Assistance Keeps the IRS at Bay"
          paraseven="Entrust your bookkeeping, taxes, and accounting to dedicated specialists. Minimize chances of IRS audits and fees."
          headeight="Monthly or Quarterly Bookkeeping"
          paraeight="Monitor business performance. Turn accounting figures into actionable information via your modern, intuitive dashboard."
          headnine="Get Advice from Bookkeeping & Tax Pros"
          paranine="Get advice or one-on-one support from dedicated specialists via email, phone, or online chat in your personalized dashboard." />

        <ThreeStepsSection head="How to Get Access to Affordable Accounting & Bookkeeping" image={Image5} imagetwo={Image6} imagethree={Image7}
          numberone="1" headingone="Book Your Free, No Obligation Tax Consultation"
          textone="In your free 30-minute consult, a specialist accountant will review your finances with you to highlight where you can save money and offer practical advice."
          numbertwo="2"
          headingtwo="Choose the Package That Suits Your Needs"
          texttwo="During your consultation, your accountant will help you determine which package (Starter or Plus) best meets your business's needs."
          numberthree="3"
          headingthree="Start Setting Up Your Dashboard"
          textthree="Your accountant will then guide you through the initial stages of populating your dashboard. Then you'll receive your login details so you can complete the setup."
        />

        <ClientSay heading="See What Our Clients Have to Say…"
          text="“One of the things I really appreciated with GYB Firm is that it took care of everything for me.”"
          smtext="Khadijah Suleman, Aloaye Clothing LLC"
          video={video}
          ThumbNail={ThumbNail} />
        <div className='clients llc-clients '>
        <ClientsData heading="rmason33" time="4 months ago" state="United States" 
      about="I am a frequent buyer on fiverr and so happy I came across Ibrahim, he is amazing at what he does." image="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/5f42910c6f360a9432f4132ff1704fb5-1639502614205/10a68fb6-0f7b-4fe3-85e2-5444e49916a2.png" />
       <ClientsData heading="nabeelzubair427" time="4 months ago" state="Saudi Arabia" 
      about="Thanks for quick service, got my IL reseller certificate within 2 days." image="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/28293fc4644de7141d7bc14d90115ba3-921758121693603165.36742/C6351E9E-3F3A-4896-86A1-11D2595F0DFA" />
      <ClientsData heading="cynthia4488" time="8 months ago" state="United States" 
      about="Great job very fast and easy to work with, definitely recommend." image="https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/057dc72b87ce58778f1256c2207aa970-1612106619213/3a92c372-a71f-4443-8090-d374d3093b89.jpg" />
        </div>

      </Container>

      <center>
        <div className='faq-main faq-llc container-fluid'>
          <h1 className='faq-heading'>Loved by 1,000,000+ Entrepreneurs Across All 50 States</h1>
          <FAQSection faqclass="faq-llc-div"
            heading="How Much Does Your Service Cost?"
            texta={<dt className='text-black'>The Starter Package costs $90 per month and includes: </dt>}
            textb={<>
              1. Quarterly bookkeeping<br />
             2. Business tax return<br />
              3. Personal taxes (for sole proprietorships and single-member LLCs only)<br />
              4.  Compliance assistance<br />
              5.Financial reporting<br />
              6. Online tools and resources<br />
              7.  Link up to two bank accounts<br />
              8. Accounting advice from our team of experts<br />
            </>}
            textc={<dt className='text-black'>The Plus package costs $180 per month. It's a more personalized service and includes the following: Monthly bookkeeping</dt>}
            textd={<>1.  Business tax return<br />
              2. Personal taxes (any entity type)<br />
              3.  Accounting advice from a dedicated bookkeeper and tax pro<br />
              4.  Compliance assistance<br />
              5.  Financial reporting<br />
              6.  Online tools and resources<br />
              7.  Link up to four bank accounts<br />
              8. Tax planning sessions with an expert

            </>}
          />
          <FAQSection faqclass="faq-llc-div"
            heading="Are There Any Hidden Costs?"
            texta="Never! We're all about transparency, and there are no hidden costs associated with our business accounting and bookkeeping service."
          />
          <FAQSection faqclass="faq-llc-div"
            heading="How Long Does It Take To Get Setup?"
            texta="Your free 30-minute tax consultation will help you choose the right package and get set up within your dashboard. After the consult, you're ready to go as soon as you receive your username and password via email." />
      
        </div>
      </center>


    </div>
  )
}

export default FinanceAccountion