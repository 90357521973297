import React from 'react';
import { motion } from "framer-motion";
import { Container } from 'react-bootstrap';
import Blogcard from '../Components/Blogcard';

function MainBlogs() {
    

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Container>
                <center>
                    <h1 className='fw-bold my-5'>Latest Posts</h1>
                </center>
                    <Blogcard />
            </Container>
        </motion.div>
    );
}

export default MainBlogs;
