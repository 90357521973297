import React from 'react'
import { motion } from "framer-motion"
import HeaderLink from '../Components/HeaderLink'
function ResourcesCenterDropDown(props) {
  return (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} >
      <div className="dropdown-menu dropdown-menu2" id="menu" onClick={props.onClick}>
          <div className="services-head-div1 px-2">
            <div className="services-div3-inner">
                <HeaderLink path="/business-entity-comparison" text="Compare Business Entities"/>
                <HeaderLink path="/business-name-generator" text="Business Name Generator"/>
                <HeaderLink path="/start-a-business"text="Business Industry Guides"/>
                <HeaderLink path="/business-name-search"text="Business Name Search Tool"/>
             

            </div>
          </div>
          <div className="services-head-div1 px-2">
            <div className="services-div3-inner">
              <HeaderLink path="/guides-start-business" text="Business Startup Guide"/>
              <HeaderLink path="/limited-liability-company" text="What is an LLC?"/>
                <HeaderLink path="/state-filing-fees" text="State Filling Fees"/>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default ResourcesCenterDropDown